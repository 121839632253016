<template>
<b-row>

    <b-col cols="12" sm="12" md="2" lg="1" xl="2"> </b-col>

    <b-col cols="12" sm="12" md="8" lg="10" xl="8"> 
        <b-row>

            <b-col cols="12" sm="12" md="12" lg="12" xl="12" style="padding-left:0px" align="center">

<b-card>

    <b-skeleton v-if="loading" width="50%"  height="10px" style="margin-bottom:10px"></b-skeleton> 
    <p v-else style="text-align:center; font-weight:bold; font-size:21px;margin:0px"><span style="font-size:11px">$</span>{{ maximoAdelanto }} <span style="font-size:11px">USD</span></p>
    <p style="text-align:center;  font-size:11px;margin:0px">Máximo de adelanto de sueldo</p>

</b-card>

</b-col>



<b-col cols="12" sm="12" md="12" lg="12" xl="12" style="padding:0px">

<b-card>



    


    <b-row v-if="loading">

            <b-col cols="12" sm="12" md="12" lg="12" xl="12" align="center">
                <b-skeleton width="50%"  height="10px" style="margin-bottom:5px"></b-skeleton> 
                <b-skeleton width="70%"  height="60px"></b-skeleton> 
            </b-col>

            <b-col cols="12" sm="3" md="3" lg="3" xl="3"></b-col>

                
            <b-col cols="12" sm="6" md="6" lg="6" xl="6" style="margin-top: 5px;">

            <b-skeleton width="50%"  height="10px"></b-skeleton>

            <table width="100%">
                    <tr>
                    <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
                    <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
                    <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
                    <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
                    <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
                    <td><b-skeleton width="100%"  height="20px"></b-skeleton></td>
                    </tr>
                </table>

            </b-col>

            <b-col cols="12" sm="3" md="3" lg="3" xl="3"></b-col>


            <b-col
                cols="4"
                    sm="4"
                    md="4"
                    lg="4"
                    xl="4"
                > </b-col>


                <b-col
                cols="4"
                    sm="4"
                    md="4"
                    lg="4"
                    xl="4"
                >

                <b-skeleton width="100%"  height="40px"></b-skeleton>
                
                </b-col>


                <b-col
                cols="4"
                    sm="4"
                    md="4"
                    lg="4"
                    xl="4"
                > </b-col>

                </b-row>



            <div v-else-if="hasAdelantoPendiente" align="center">

            <img style="width:100px;" src="/img/caution.svg" />

            <p style="margin-top:10px; font-size:14px; ">No puede utilizar esta funcionalidad debido a que usted tiene una solicitud de adelanto de sueldo activo. Pague el adelanto de sueldo pendiente o espere hasta que se renueve un nuevo intento.</p>



            </div>

            <div v-else-if="notHasPermisoSolicitudAdelantoEmpresa" align="center">

            <img style="width:100px;" src="/img/caution.svg" />

            <p style="margin-top:10px; font-size:14px; ">Este negocio no tiene habilitado la funcionalidad de solicitar adelantos de sueldos.</p>



            </div>

            <div v-else-if="notHasPlan" align="center">

            <img style="width:100px;" src="/img/caution.svg" />

            <p style="margin-top:10px; font-size:14px; ">La funcionalidad de solicitud de adelanto de sueldo se encuentra desactivado debido a que este negocio no tiene activado el plan de negocios EQCoop.</p>



            </div>



            <div v-else>

            <b-form
            autocomplete="off"
            @submit.prevent="onSubmit"
            >

            <b-row>

                <b-col
                sm="12"
                md="12"
                lg="12"
                xl="12"
            >

            
            <p class="textLabel" style="text-align: center;margin-top: 0px;margin-bottom: 5px;">
                    Ingrese el monto a solicitar el adelanto de sueldo (*):</p>

                        

              <AutoNumericVue
                  v-model="mount"
                  autofocus
                  required
                  @blur="onBlurMount"
                  @input="typeMountTransfer2"
                  type="text" inputmode="decimal"
                  :readonly="isActive"
                  class="autonumeric_input"
                  :options="json_opciones"
                  :placeholder="'$0.00'"
              ></AutoNumericVue>


            </b-col>

            <b-col
                sm="12"
                md="12"
                lg="12"
                xl="12"
            >

            <b-alert
                variant="secondary"
                show
              >
                <div class="alert-body">
             

                        
                          <p style="text-align: center;font-size:25px; margin-bottom:0px; font-weight:bold"> <span style="font-size:12px">$</span>{{ mountAdelanto }} <span style="font-size:12px">USD</span></p>
                          <p style="text-align: center;font-size:11px; margin-bottom:0px">Monto a transferirse hacia tu billetera <br> (incluye comisión de EQCoop) </p>

             
                </div>
              </b-alert>

              </b-col>


            



                <b-col
                sm="12"
                md="12"
                lg="12"
                xl="12"
                style="margin-top:15px"
            >

                <p
                class="textLabel"
                style="text-align: center;margin-bottom: 10px;"
                >
                Ingrese su código PIN (*): <feather-icon
                                    icon="HelpCircleIcon"
                                    size="16"
                                    v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                                    title="Código PIN de seguridad"
                                    style="margin-right: 5px;cursor:pointer;"
                                    /> </p>

                <PincodeInput
                v-model="pin"
                placeholder="-"
                :length="6"
                :autofocus="false"
                :secure="true"
                :disabled="isActive"
                required
                />

            </b-col>

            <b-col
                sm="12"
                md="12"
                lg="12"
                xl="12"
            >

                <b-button
                id="btn_confirmar"
                ref="btn_confirmar"
                type="submit"
                variant="primary"
                :disabled="isActive"
                block
                >

                Solicitar adelanto de sueldo 

                </b-button>

            </b-col>



                </b-row>


                </b-form>
            </div>

</b-card>





</b-col>



        </b-row>
    </b-col>


    <b-col cols="12" sm="12" md="2" lg="10" xl="2"> </b-col>

 

</b-row>


    

</template>

<script>
import {
    BButton, BForm, BAlert, BRow, BCol, BCard,BSkeleton,VBPopover
} from 'bootstrap-vue'

import AutoNumericVue from 'autonumeric-vue/src/components/AutoNumericVue';  
import PincodeInput from 'vue-pincode-input'
          

export default {
    components: {
    BAlert,
    BSkeleton,
    BButton,
    AutoNumericVue,
    PincodeInput,
    BForm,
    BRow,
    BCol,
    BCard
    },
    directives: {
        'b-popover': VBPopover,
    },
    props: ['userId','tokenAuth', 'idLocalEmployee', 'sueldo'],
    data() {


    return {
        loading:true,
        hasAdelantoPendiente:false,
        notHasPlan:false,
        notHasPermisoSolicitudAdelantoEmpresa:false,
        porcentajeComisionAdelantoSueldos:0,
        maximoAdelanto:0,
        pin:"",
        isActive:false,
        mount:"",
        json_opciones:{},
        mountAdelanto:"0.00"
    }
    },
    computed: {

    },
    watch: {

    },
    mounted() {

  
    this.checkAdelantoSueldo();

    },
    methods: {
        onSubmit(event){

            event.preventDefault();


            this.isActive=true;
                    
                    const local_json = {
                        userId: this.userId, idLocalEmployee: this.idLocalEmployee, pin: this.pin, mount: this.mount
                        }
            
                    
            
            
            
                    const local_string = JSON.stringify(local_json)
                
                    const dataEncripted = this.$encryptBackoffice.encrypt(local_string)
            
                    document.getElementById('btn_confirmar').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Solicitando'
            
                
                    this.$https.post('/locals/requestAdelantoSueldo/', { tokenAuth: this.tokenAuth, data: dataEncripted }).then(response => {
                    document.getElementById('btn_confirmar').innerHTML = 'Solicitar adelanto de sueldo ';
                    this.isActive=false;
                    
                
                    if (response.data.code == 200) {
            
                    this.$eventBus.$emit('reiniciarAdelantos');    
                    this.$eventBus.$emit('reiniciarListadoAdelantos')
                    this.$toast.success(response.data.message, {
                        position: 'top-right',
                        timeout: 3010,
                        closeOnClick: true,
                        pauseOnFocusLoss: false,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.4,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: 'button',
                        icon: true,
                        rtl: false,
                    });
            
                    
            
                    } else {

                        this.$eventBus.$emit('reiniciarAdelantos');    
           
            
                    this.$toast.error(response.data.message, {
                        position: 'top-right',
                        timeout: 3010,
                        closeOnClick: true,
                        pauseOnFocusLoss: false,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.4,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: 'button',
                        icon: true,
                        rtl: false,
                    })
            
                    if (response.data.code == 401) {
            
                    
            
                    localStorage.removeItem('userData')
            
            
                    this.$router.push({ name: 'auth-login' })
                    }
                    }
                    }).catch(error => {
                    this.$toast.error("Ocurrio un error " + error, {
                        position: 'top-right',
                        timeout: 3010,
                        closeOnClick: true,
                        pauseOnFocusLoss: false,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.4,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: 'button',
                        icon: true,
                        rtl: false,
                    })
                    })
            
                        
            


        },

        onBlurMount(){
        
    
        if(Number(this.mount) === Number("0")){

            this.mount="";

        }

    },

    typeMountTransfer2(value){
    

    if(value === "" || value === "null" || value === null  || value === 0){

       
         

         this.mountAdelanto="0.00";




       }else{

             
                   let mounTrans=Number(Number(value)  * Number(this.porcentajeComisionAdelantoSueldos))/Number("100");
                   let final= Number(Number(value) - Number(mounTrans)).toFixed(2)

                   this.mountAdelanto= final;
    


       }

},


    checkAdelantoSueldo(){

        

        this.loading = true;  
        this.hasAdelantoPendiente=false;

        this.notHasPermisoSolicitudAdelantoEmpresa=false;

              const userId_json = { userId: this.userId, idLocalEmployee: this.idLocalEmployee }

  

              this.$https.post('/locals/checkAdelantoSueldo/', { tokenAuth: this.tokenAuth, data: userId_json }).then(response => {
          
                if (response.data.code == 200) {

                this.maximoAdelanto = Number(Number(this.sueldo) * Number(response.data.porcentajeMaximoAdelantoSueldo)).toFixed(2)

                if(Number(this.maximoAdelanto) > Number(response.data.maximoAdelantoSueldo)){
                    this.maximoAdelanto= Number(response.data.maximoAdelantoSueldo).toFixed(2)
                }
                

                  this.porcentajeComisionAdelantoSueldos=response.data.porcentajeComisionAdelantoSueldos;

                  this.json_opciones={allowDecimalPadding: "false", currencySymbol: "$", decimalCharacterAlternative: ",",decimalCharacter:".",   minimumValue: 0, maximumValue:this.maximoAdelanto, modifyValueOnWheel: false };
    
                  this.loading = false;
              
                    this.hasAdelantoPendiente=false;

                    this.notHasPermisoSolicitudAdelantoEmpresa=false;
                    this.notHasPlan=false;
           

                } else {


                  if (response.data.code == 401) {

                    this.$toast.error(response.data.message, {
                    position: 'top-right',
                    timeout: 3010,
                    closeOnClick: true,
                    pauseOnFocusLoss: false,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.4,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: 'button',
                    icon: true,
                    rtl: false,
                  })
                    
                    localStorage.removeItem('userData')

                    

                    // Redirect to login page
                    this.$router.push({ name: 'auth-login' })
                  } else {

                    if( response.data.code === 403){
                        this.loading = false;
              
                            this.hasAdelantoPendiente=true;

                            this.notHasPermisoSolicitudAdelantoEmpresa=false;
                    }   if( response.data.code === 402){
                        this.loading = false;
              
                            this.hasAdelantoPendiente=false;

                            this.notHasPermisoSolicitudAdelantoEmpresa=true;
                    }else{

                        if( response.data.code === 404){
                            this.$toast.error(response.data.message, {
                                position: 'top-right',
                                timeout: 3010,
                                closeOnClick: true,
                                pauseOnFocusLoss: false,
                                pauseOnHover: true,
                                draggable: true,
                                draggablePercent: 0.4,
                                showCloseButtonOnHover: false,
                                hideProgressBar: true,
                                closeButton: 'button',
                                icon: true,
                                rtl: false,
                            })
                            
                             this.$eventBus.$emit('activeHome');
                                 this.$eventBus.$emit('reiniciarAllNegocio');
                            }else{

                                if( response.data.code === 405 ){

                                    this.loading = false;
              
                                    this.hasAdelantoPendiente=false;

                                    this.notHasPermisoSolicitudAdelantoEmpresa=false;

                                    this.notHasPlan=true;

                                }else{
                                    this.loadGeneral();
                                }
                               
                            }
                    
                    }

                  }
                }
              }).catch(error => {

                this.loadGeneral();
                
              })


    },
    

    },
}
</script>

<style lang="scss">



</style>
    